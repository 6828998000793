import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import BlogList from '../components/blog-list';
export const _frontmatter = {
  "path": "/blog",
  "pageType": "page",
  "title": "Blog posts",
  "backgroundImage": "typewriter"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`As you may be able to tell, I've not been consistent with writing blog posts. ...Or maybe I have, by simply not actually writing any!`}</p>
    <BlogList mdxType="BlogList" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      